<template>
  <div>
    <CreateUser Header="สร้างผู้ใช้งาน" />
  </div>
</template>
<script>
import CreateUser from '@/components/CreateUser'
export default {
  components: {
    CreateUser
  }
}
</script>
