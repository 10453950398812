<template>
  <sequential-entrance fromTop delay="5000">
    <Login />
  </sequential-entrance>
</template>
<script>
import Login from '@/components/Login'
export default {
  components: {
    Login
  }
}
</script>
