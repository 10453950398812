<template>
  <div>
   <CreateUser Header="เเก้ไขผู้ใช้งาน" />
  </div>
</template>
<script>
import CreateUser from '@/components/CreateUser'
export default {
  components: {
    CreateUser
  }
}
</script>
